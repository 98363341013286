import { Grid, Typography } from "@material-ui/core"
import React, { ReactElement } from "react"
import InstagramEmbed from "react-instagram-embed"

import BlogPost from "../../../../components/BlogPost"
import { SearchResults } from "../../../../components/SearchResults"

interface Props {
  data: any
  location: Location
  pageContext: any
}

export default function Post(props: Props): ReactElement {
  const { location, pageContext } = props
  return (
    <BlogPost
      location={location}
      pageContext={pageContext}
      title="Warme Pullover für den Winter"
      category="Wintertrends 2019/2020"
    >
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <Typography paragraph={true}>
            Die Influencerin und zugleich Unternehmerin Negin Mirsalehi zeigt uns ihren Look: Ein
            Strickpullover kombiniert mit einem klassischen Blazer, gestreifte Hosen und weiße
            Sneakers. Dazu trägt sie eine Schultertasche von Gucci aus der neuen Kollektion. Das
            Outfit ist komplett und zugleich sehr modisch. Strickpullover können mit Midi-Röcken
            oder Jeans kombiniert werden. Wer Farben mag, der wird den rostorangen Strickpullover
            von AJC lieben! Der marine s.Oliver Strickpullover mit hohem Stehkragen ist perfekt für
            den Herbst. Strickpullover sind warm und kuschelig – Wir lieben Strickpullover!
          </Typography>
        </Grid>
      </Grid>

      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <SearchResults
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24696129351&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35029880?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "Herrlicher Rollkragenpullover",
                price: 79.95,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608351147&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/31085197?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "GUIDO MARIA KRETSCHMER Strickpullover",
                price: 69.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24634439837&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/33504230?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "AJC Strickpullover",
                price: 39.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=25532597175&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35564160?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "OPUS Strickpullover",
                price: 69.95,
              },
            ]}
          />
        </Grid>
      </Grid>

      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={6}>
          <InstagramEmbed
            url="https://www.instagram.com/p/B3FaTFfoFbH/"
            hideCaption={true}
            injectScript={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <SearchResults
            breakpoints={{
              lg: 6,
              md: 6,
              xs: 6,
            }}
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24647316373&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/34923352?w=1120&h=1490",
                    title: "",
                  },
                ],
                name: "TOM TAILOR Strickpullover",
                price: 59.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24609296525&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/33475819?w=1708&h=2441",
                    title: "",
                  },
                ],
                name: "Pepe Jeans Strickpullover",
                price: 89.9,
              },
              {
                href: "https://www.awin1.com/pclick.php?p=25334439247&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/36367827?w=1232&h=1776",
                    title: "",
                  },
                ],
                name: "s.Oliver Strickpullover",
                price: 49.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24798898923&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35136755?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "OPUS Strickpullover",
                price: 69.95,
              },
            ]}
          />
        </Grid>
      </Grid>
    </BlogPost>
  )
}
